<template>
  <section>
    <div>
      <VProgressCircular
        :size="90"
        width="3"
        color="primary"
        indeterminate
      >
        <VIcon
          x-large
          color="primary"
        >
          mdi-account
        </VIcon>
      </VProgressCircular>
    </div>
    <h3>Logging Out, Please Wait...</h3>
  </section>
</template>

<script>
import fire from '../conf/firebaseAuth';

export default {
  beforeCreate() {
    if (this.$store.state.currentUser === null) {
      setTimeout(() => {
        this.$router.push('/');
      }, 100);
    }
  },
  mounted() {
    setTimeout(() => {
      this.signOut();
      this.$router.push('/');
    }, 1000);
  },
  methods: {
    async signOut() {
      await fire.signOut();
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../assets/stylus/logout';
</style>
